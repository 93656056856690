import { httpHelper } from "./http.helper";
import { jsHelper } from "./js.helper";
import { singleton } from "./singleton";

export interface IHttpResponse {
	data: any;
	status: number;
	response: Response;
}

export interface ExtConfig {
	responseOK?: (response: any) => boolean;
	errorMsg?: string;
}

class HttpClient {
	get = async (
		path: string,
		configs?: RequestInit | any
	): Promise<IHttpResponse> => {
		return this.req(path, Object.assign({ method: "GET" }, configs));
	};

	post = async (
		path: string,
		body?: any,
		configs?: RequestInit | any
	): Promise<IHttpResponse> => {
		body = body ? body : configs?.body;
		return this.req(path, Object.assign({ method: "POST", body }, configs));
	};

	req = async (
		path: string,
		configs: RequestInit | any
	): Promise<IHttpResponse> => {
		const extConfig: ExtConfig = { ...configs?.extConfig };

		if (configs?.extConfig) delete configs.extConfig;
		const ops = Object.assign(
			{
				headers: {
					"Content-Type": "application/json",
				},
			},
			configs
		);

		if (ops.body) ops.body = JSON.stringify(ops.body);

		const response = await fetch(path, ops);
		let data;
		try {
			const txt = await response.text();
			if (txt.startsWith("{") || txt.startsWith("[")) data = JSON.parse(txt);
			else data = txt;
		} catch (error) {
			console.debug(error);
		}

		const s = response.status;
		const isNOK = extConfig?.responseOK
			? !extConfig?.responseOK?.(response)
			: !httpHelper.isOK(s);
		if (isNOK) {
			const errMsg = extConfig?.errorMsg;
			const emsg =
				errMsg ??
				(data ? jsHelper.handleError(data) : null) ??
				`HTTP Error: ${s} ${response.statusText}`;
			throw new Error(emsg);
		}

		return {
			data,
			status: s,
			response,
		};
	};
}
export const httpClient = singleton(HttpClient);

export const HttpStatusCode: Record<number, string> = {
	100: "Continue",
	101: "Switching Protocols",
	102: "Processing",
	103: "Early Hints",
	200: "OK",
	201: "Created",
	202: "Accepted",
	203: "Non-Authoritative Information",
	204: "No Content",
	205: "Reset Content",
	206: "Partial Content",
	207: "Multi-Status",
	208: "Already Reported",
	226: "IM Used",
	300: "Multiple Choices",
	301: "Moved Permanently",
	302: "Found",
	303: "See Other",
	304: "Not Modified",
	305: "Use Proxy",
	307: "Temporary Redirect",
	308: "Permanent Redirect",
	400: "Bad Request",
	401: "Unauthorized",
	402: "Payment Required",
	403: "Forbidden",
	404: "Not Found",
	405: "Method Not Allowed",
	406: "Not Acceptable",
	407: "Proxy Authentication Required",
	408: "Request Timeout",
	409: "Conflict",
	410: "Gone",
	411: "Length Required",
	412: "Precondition Failed",
	413: "Payload Too Large",
	414: "URI Too Long",
	415: "Unsupported Media Type",
	416: "Range Not Satisfiable",
	417: "Expectation Failed",
	418: "I'm a Teapot",
	421: "Misdirected Request",
	422: "Unprocessable Entity",
	423: "Locked",
	424: "Failed Dependency",
	425: "Too Early",
	426: "Upgrade Required",
	428: "Precondition Required",
	429: "Too Many Requests",
	431: "Request Header Fields Too Large",
	451: "Unavailable For Legal Reasons",
	500: "Internal Server Error",
	501: "Not Implemented",
	502: "Bad Gateway",
	503: "Service Unavailable",
	504: "Gateway Timeout",
	505: "HTTP Version Not Supported",
	506: "Variant Also Negotiates",
	507: "Insufficient Storage",
	508: "Loop Detected",
	509: "Bandwidth Limit Exceeded",
	510: "Not Extended",
	511: "Network Authentication Required",
};
