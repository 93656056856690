import "./v1-lib/buffer"
import { SimpleAccountAPI } from "@account-abstraction/sdk"
import { ethers } from "ethers"
import { MSoulsToken__factory, PlayToEarn, PlayToEarn__factory } from "./typechain"
import { EntryPoint__factory, SimpleAccountFactory__factory, SimpleAccount__factory } from "./v1-lib/aa-typechain"
import { httpClient } from "./v1-lib/common/httpClient"
import { aaHelper } from "./v1-lib/helpers/aa/aa.helper"
import { generateHashWithBcrypt } from "./v1-lib/helpers/token.helper"
import { securityService } from "./v1-lib/services/security.service"
import { error } from "console"

declare var vuplex: any;
// export async function getHashedKey() {
//     // alert("get hashed key")
//     const uuid = "9259a30e-68ed-498b-85a1-4888a6d3b2ab"
//     const privateKeyHashRequest = await httpClient.post(process.env.REACT_APP_WALLET_API_URL + "wallet/getEncodedPrivateKey", {
//         uuid
//     })
//     // console.log(privateKeyHashRequest.data)
//     return JSON.stringify(privateKeyHashRequest.data)
// }

export async function createAccount(mail: string, password: string) {
    try {

        const passwordHash = await generateHashWithBcrypt(password)
        const wallet = ethers.Wallet.createRandom();
        const addresses = await aaHelper.getAccountAPI(wallet.privateKey);
        const encryptedPrivateKeyAndIv = securityService.encrypt(wallet.privateKey, Buffer.from(password))

        const createWalletResult = await httpClient.post(process.env.REACT_APP_WALLET_API_URL + `wallet/createWalletQuick`, {
            mail,
            passwordHash,
            addresses,
            encryptedPrivateKey: encryptedPrivateKeyAndIv.encryptedData,
            iv: encryptedPrivateKeyAndIv.iv
        });
        console.log(JSON.stringify({ result: createWalletResult.data, type: "CreateAccountResult" }))

        if (typeof vuplex != "undefined") {
            vuplex.postMessage(JSON.stringify({ result: { error: false, errorMessage: "", ...createWalletResult.data }, type: "CreateAccountResult" }));
        }
    } catch (error: any) {
        console.error({ result: { error: !!error, errorMessage: error }, type: "CreateAccountResult" })
        if (typeof vuplex != "undefined") {
            vuplex.postMessage(JSON.stringify({ result: { error: !!error, errorMessage: error.toString() }, type: "CreateAccountResult" }));
        }
    }


}

export async function getAccountAddress(mail: string) {
    const privateKeyHashRequest = await httpClient.post(process.env.REACT_APP_WALLET_API_URL + "wallet/getAccountAddressByMail", {
        mail
    })
    const walletAddress = privateKeyHashRequest.data.walletAddress
    console.log("walletAddress", walletAddress)
}

const sendTransaction = async (txTarget: string, txData: string, uuid: string, password: string, provider: ethers.providers.JsonRpcProvider,) => {

    const privateKeyHashRequest = await httpClient.post(process.env.REACT_APP_WALLET_API_URL + "wallet/getEncodedPrivateKey", {
        uuid
    })

    const privateKeyHash = privateKeyHashRequest.data.privateKey
    const iv = privateKeyHashRequest.data.iv
    const walletAddress = privateKeyHashRequest.data.walletAddress
    const privateKey = securityService.decrypt(privateKeyHash, Buffer.from(password!.toString()), Buffer.from(iv, "hex"))
    const accountOwner = new ethers.Wallet(privateKey)

    let accountApi = new SimpleAccountAPI({
        provider,
        entryPointAddress: process.env.REACT_APP_CHAIN_ENTRY_POINT!,
        factoryAddress: process.env.REACT_APP_CHAIN_SIMPLE_ACCOUNT_FACTORY!,
        owner: accountOwner,
        index: 0,
        overheads: {
            // perUserOp: 100000
        }
    })
    const simpleAccountContract = SimpleAccount__factory.getInterface(SimpleAccount__factory.abi)
    const execFromSingleton = simpleAccountContract.encodeFunctionData('execute', [txTarget, 0, txData])
    const entryPoint = EntryPoint__factory.connect(process.env.REACT_APP_CHAIN_ENTRY_POINT!, provider)
    const accountFactory = SimpleAccountFactory__factory.connect(
        process.env.REACT_APP_SIMPLE_ACCOUNT_FACTORY!,
        provider
    );
    const byteCode = await provider.getCode(walletAddress)
    console.log(walletAddress, byteCode)
    let initCode = undefined
    if (byteCode == "0x") {
        initCode = ethers.utils.hexConcat([
            accountFactory.address,
            accountFactory.interface.encodeFunctionData("createAccount", [privateKeyHashRequest.data.owner, ethers.BigNumber.from(0)])
        ])

    }

    const userOp = await aaHelper.fillAndSign({
        sender: walletAddress,
        callData: execFromSingleton,
        initCode,
        preVerificationGas: '0x' + 1e5.toString(16),
        paymasterAndData: process.env.REACT_APP_CHAIN_PAYMASTER,
    }, accountOwner, entryPoint)



    const promises = Promise.all(Object.keys(userOp).map(async (key: unknown) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        userOp[key] = await userOp[key]
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (typeof userOp[key] == "object") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            userOp[key] = Number(userOp[key])
        }
        /* eslint-enable */
    }))
    await promises

    const executionRequest = await httpClient.post(process.env.REACT_APP_WALLET_API_URL + "wallet/executeSignedUserOp", {
        userOp,
        chainId: (await provider.getNetwork()).chainId,
        uuid
    })

    const userOpHash = executionRequest.data

    console.log("userOpHash", userOpHash)
    const txid = await accountApi.getUserOpReceipt(userOpHash)

    if (txid) {
        await provider.waitForTransaction(txid)
    }
    return txid

}

// export async function defineClaim(to: string, amount: number | string) {
//     try {
//         const definationRequest = await httpClient.post("https://uf-mobile-api.strady.com/defineClaim", {
//             to,
//             amount
//         })
//         const result = definationRequest.data
//         console.log({ result, type: "DefineClaimResult" })

//         if (typeof vuplex != "undefined") {
//             vuplex.postMessage(JSON.stringify({ result: { ...result, error: false, errorMessage: "" }, type: "DefineClaimResult" }));
//         }

//     } catch (error: any) {
//         console.error({ result: { error: !!error, errorMessage: error }, type: "DefineClaimResult" })
//         if (typeof vuplex != "undefined") {
//             vuplex.postMessage(JSON.stringify({ result: { error: !!error, errorMessage: error.toString() }, type: "DefineClaimResult" }));
//         }
//     }

// }
export async function claim(uuid: string, address: string, password: string, amount: string, data: string, decimals: string) {
    console.log(uuid, address, password, amount, data, decimals)
    try {
        console.log("process.env.REACT_APP_CHAIN_RPC_DEFAULT", process.env.REACT_APP_CHAIN_RPC_DEFAULT)
        const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_CHAIN_RPC_DEFAULT)
        const claimData = new PlayToEarn__factory().interface.encodeFunctionData("claim", [ethers.utils.parseEther(amount + ""), JSON.parse(data).merkleProof])
        const txId = await sendTransaction(process.env.REACT_APP_PLAY_TO_EARN_ADDRESS!, claimData, uuid, password, provider)
        const tokenContract = MSoulsToken__factory.connect(process.env.REACT_APP_TOKEN_ADDRESS!, provider)
        const tokenBalance = Number(ethers.utils.formatEther(await tokenContract.balanceOf(address))).toFixed(Number(decimals) || 4)
        const slot = await provider.getStorageAt(process.env.REACT_APP_PLAY_TO_EARN_ADDRESS!, "0x4")
        const period = Number(slot)
        console.log(JSON.stringify({ result: { txId: "", tokenBalance }, type: "ClaimResult" }))

        if (typeof vuplex != "undefined") {
            vuplex.postMessage(JSON.stringify({ result: { error: false, errorMessage: "", txId, tokenBalance, period }, type: "ClaimResult" }));
        }
    } catch (error: any) {
        console.log("error", error)
        console.error({ result: { error: !!error, errorMessage: error }, type: "ClaimResult" })
        if (typeof vuplex != "undefined") {
            vuplex.postMessage(JSON.stringify({ result: { error: !!error, errorMessage: error.toString() }, type: "ClaimResult" }));
        }
    }
}

// export async function activateCharacter(forPlayer: string, cost: number | string, uuid: string, password: string) {
//     try {
//         const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_CHAIN_RPC_DEFAULT)

//         const tokenContract = MSoulsToken__factory.connect(process.env.REACT_APP_TOKEN_ADDRESS!, provider)
//         const playToEarnContract = PlayToEarn__factory.connect(process.env.REACT_APP_PLAY_TO_EARN_ADDRESS!, provider);
//         console.log("contracts initialized")
//         const approval = await tokenContract.allowance(forPlayer, playToEarnContract.address)
//         if (approval.lt(ethers.utils.parseEther(cost + ""))) {
//             console.log("approve", playToEarnContract.address, ethers.constants.MaxUint256)
//             let approveData = tokenContract.interface.encodeFunctionData("approve", [playToEarnContract.address, ethers.constants.MaxUint256])
//             console.log("send transaction", uuid, password, approveData)
//             const txId = await sendTransaction(process.env.REACT_APP_TOKEN_ADDRESS!, approveData, uuid, password, provider)
//             provider.waitForTransaction(txId!)

//         }
//         const activationRequest = await httpClient.post("https://uf-mobile-api.strady.com/activateCharacter2", {
//             forPlayer,
//             cost
//         })
//         const result = await activationRequest.data
//         console.log({ result, type: "ActivateCharacterResult" })
//         if (typeof vuplex != "undefined") {
//             vuplex.postMessage(JSON.stringify({ result: { ...result, error: false, errorMessage: "" }, type: "ActivateCharacterResult" }));
//         }
//     } catch (error: any) {
//         console.error({ result: { error: !!error, errorMessage: error.toString() }, type: "ActivateCharacterResult" })
//         if (typeof vuplex != "undefined") {
//             vuplex.postMessage(JSON.stringify({ result: { error: !!error, errorMessage: error.toString() }, type: "ActivateCharacterResult" }));
//         }
//     }
// }

export async function approveTokens(uuid: string, address: string, password: string) {
    try {
        const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_CHAIN_RPC_DEFAULT)

        const tokenContract = MSoulsToken__factory.connect(process.env.REACT_APP_TOKEN_ADDRESS!, provider)
        const approval = await tokenContract.allowance(address, process.env.REACT_APP_PLAY_TO_EARN_ADDRESS!)

        if (approval.lt(ethers.constants.MaxUint256)) {
            let approveData = tokenContract.interface.encodeFunctionData("approve", [process.env.REACT_APP_PLAY_TO_EARN_ADDRESS!, ethers.constants.MaxUint256])
            const txId = await sendTransaction(process.env.REACT_APP_TOKEN_ADDRESS!, approveData, uuid, password, provider)
            provider.waitForTransaction(txId!)
        }
        console.log({ result: { approved: true, error: false, errorMessage: "" }, type: "ApproveTokensResult" })
        console.log(JSON.stringify({ result: { approved: true, error: false, errorMessage: "" }, type: "ApproveTokensResult" }))
        if (typeof vuplex != "undefined") {
            vuplex.postMessage(JSON.stringify({ result: { approved: true, error: false, errorMessage: "" }, type: "ApproveTokensResult" }));
        }


    } catch (error: any) {
        console.error({ result: { error: !!error, errorMessage: error.toString() }, type: "ApproveTokensResult" })
        if (typeof vuplex != "undefined") {
            vuplex.postMessage(JSON.stringify({ result: { error: !!error, errorMessage: error.toString() + ", " + address }, type: "ApproveTokensResult" }));
        }
    }
}

export async function getTokenBalance(address: string, decimals: number) {
    try {
        const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_CHAIN_RPC_DEFAULT)
        const tokenContract = MSoulsToken__factory.connect(process.env.REACT_APP_TOKEN_ADDRESS!, provider)
        const tokenBalance = Number(ethers.utils.formatEther(await tokenContract.balanceOf(address))).toFixed(decimals || 4)
        console.log({ result: { error: false, errorMessage: "", tokenBalnce: tokenBalance }, type: "TokenBalanceResult" })
        console.log(JSON.stringify({ result: { error: false, errorMessage: "", tokenBalnce: tokenBalance }, type: "TokenBalanceResult" }))
        if (typeof vuplex != "undefined") {
            vuplex.postMessage(JSON.stringify({ result: { error: false, errorMessage: "", tokenBalance }, type: "TokenBalanceResult" }));
        }

    } catch (error: any) {
        console.error({ result: { error: !!error, errorMessage: error }, type: "TokenBalanceResult" })
        if (typeof vuplex != "undefined") {
            vuplex.postMessage(JSON.stringify({ result: { error: !!error, errorMessage: error.toString() }, type: "TokenBalanceResult" }));
        }
    }
}


