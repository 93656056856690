import { Provider } from "@ethersproject/abstract-provider";
import { JsonRpcProvider } from "@ethersproject/providers";
import { BigNumber, constants, Signer, utils } from "ethers";
import { singleton } from "../../common/singleton";
import { ERC20__factory } from "../../aa-typechain";

class ChainHelper {
  private _signer: Signer | undefined;
  private _provider: Provider | undefined;
  private _addr: string | undefined;

  signer = (_signer?: Signer | null): Signer | undefined => {
    if (_signer) this._signer = _signer;
    return this._signer;
  };

  provider = (_provider?: Provider): Provider => {
    if (_provider) this._provider = _provider;

    return this._provider!;
  };

  jsonRpcProvider = (_provider?: Provider): JsonRpcProvider => {
    if (_provider) this._provider = _provider;

    return this._provider! as JsonRpcProvider;
  };

  sop = (): Signer | Provider => {
    return this._signer || this._provider!;
  };

  address = (_addr?: string): string => {
    if (_addr) this._addr = _addr;

    return this._addr!;
  };

  formatPrice = (value: BigNumber, digits: number = 4): string => {
    return (+utils.formatEther(value)).toFixed(digits);
  };

  getBalance = async (account: string, address: string): Promise<string> => {
    const con = await ERC20__factory.connect(address, chainHelper.provider());
    return this.formatPrice(await con.balanceOf(account));
  };

  toBigNumber = (num: number, decimals: number): BigNumber => {
    return BigNumber.from(utils.parseUnits(num.toFixed(decimals), decimals));
  };

  formatEther = (num: BigNumber, decimals: number): string => {
    return utils.formatUnits(num, decimals);
  };

  formatBigNumber = (
    num: BigNumber,
    decimals: number
  ): string => {
    return this.formatEther(num, decimals);
  };

  isAddress = (address: string | undefined): boolean => {
    return (
      address != undefined &&
      address.length === constants.AddressZero.length &&
      address !== constants.AddressZero
    );
  };
}
export const chainHelper = singleton(ChainHelper);
