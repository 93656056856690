
import {
	createCipheriv,
	createDecipheriv,
	createHash,
	randomBytes
} from "crypto-browserify";

// import { randomBytes } from "crypto"
import { singleton } from "../common/singleton";

const algorithm = "aes-256-cbc";


class SecurityService {
	encrypt = (message: string, secret: Buffer): any => {


		const iv = randomBytes(16)
		// const hash = createHash("sha1");

		// hash.update(salt);
		const SECRET = Buffer.from(secret.toString().padEnd(32, "F"));


		const cipher = createCipheriv(
			algorithm,
			SECRET,
			iv
		);
		let encryptedData = cipher.update(message, "utf-8", "hex");

		encryptedData += cipher.final("hex");

		return { encryptedData, iv: iv.toString("hex") };
	};



	decrypt = (encryptedData: string, secret: Buffer, iv: Buffer): string => {

		const SECRET = Buffer.from(secret.toString().padEnd(32, "F"));

		const decipher = createDecipheriv(algorithm, SECRET, iv);
		let decryptedData = decipher.update(encryptedData, "hex", "utf-8");
		decryptedData += decipher.final("utf-8");

		return decryptedData;
	};


	sha256 = (buffer: string) => createHash("sha256").update(buffer).digest();
}
export const securityService = singleton(SecurityService);
