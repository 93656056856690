import { useEffect } from 'react';
import './App.css';
import { createAccount, claim, getTokenBalance, approveTokens, getAccountAddress } from './apiHelper';

function App() {
  useEffect(() => {
    // window.Buffer = window.Buffer || require("buffer").Buffer;
    (window as any).createAccount = createAccount;
    (window as any).claim = claim;
    (window as any).approveTokens = approveTokens;
    // (window as any).activateCharacter = activateCharacter;
    (window as any).getTokenBalance = getTokenBalance;
    (window as any).getAccountAddress = getAccountAddress;

  }, [])

  return (
    <div>

    </div>
  );
}

export default App;
