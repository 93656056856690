import jwtDecode from 'jwt-decode';
import bcrypt from "bcryptjs"
export const parseChaMalletToken = (): any | undefined => {
    try {
        const chaMalletToken = localStorage.getItem("chaMalletToken");
        if (!chaMalletToken) return;

        const payload = jwtDecode(chaMalletToken);
        console.log("token payload", payload);
        return payload
        //   setChaMalletInfo(payload);
    } catch (error) {
        console.error("Token Decode ERROR", error);
        throw new Error("Token Decode Error")
    } finally {
    }
};

export const generateHashWithBcrypt = (secret: string) => {
    return new Promise<string>((resolve) => {


      bcrypt.genSalt(10, function (err, salt) {
        if (err) return console.error("bcrypt error", err)
        bcrypt.hash(secret, salt, function (err, hash) {
          if (err) return console.error("bcrypt error", err)
          resolve(hash)
        });
      });

      

    })
  }