
import { SimpleAccountAPI } from "@account-abstraction/sdk";
import { getUserOpHash, packUserOp } from "@account-abstraction/utils";
import { BigNumber, BigNumberish, Contract, Signer, Wallet, ethers } from "ethers";
import { arrayify, hexDataSlice } from "ethers/lib/utils";
import { EntryPoint } from "../../aa-typechain";
import { singleton } from "../../common/singleton";
import { chainHelper } from "../chain/chain.helper";
import { Create2Factory } from "./Create2Factory";
import { DefaultsForUserOp, UserOperation } from "./UserOperation";

export interface Gas {
    maxFeePerGas: BigNumberish;
    maxPriorityFeePerGas: BigNumberish;
}


class AAHelper {

    callDataCost(data: string): number {
        return ethers.utils.arrayify(data)
            .map(x => x === 0 ? 4 : 16)
            .reduce((sum, x) => sum + x)
    }



    fillUserOpDefaults(op: Partial<UserOperation>, defaults = DefaultsForUserOp): UserOperation {
        const partial: any = { ...op }
        // we want "item:undefined" to be used from defaults, and not override defaults, so we must explicitly
        // remove those so "merge" will succeed.
        for (const key in partial) {
            if (partial[key] == null) {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete partial[key]
            }
        }
        const filled = { ...defaults, ...partial }
        return filled
    }

    async fillUserOp(op: Partial<UserOperation>, entryPoint?: EntryPoint, getNonceFunction = 'getNonce'): Promise<UserOperation> {
        console.log("fillUserOp")
        const op1 = { ...op }
        const provider = entryPoint?.provider
        if (op.initCode != null) {
            console.log("fillUserOp1")

            const initAddr = hexDataSlice(op1.initCode!, 0, 20)
            const initCallData = hexDataSlice(op1.initCode!, 20)
            if (op1.nonce == null) op1.nonce = 0
            console.log("fillUserOp2")
            if (op1.sender == null) {
                console.log("fillUserOp3")
                // hack: if the init contract is our known deployer, then we know what the address would be, without a view call
                if (initAddr.toLowerCase() === Create2Factory.contractAddress.toLowerCase()) {
                    console.log("fillUserOp4")
                    const ctr = hexDataSlice(initCallData, 32)
                    const salt = hexDataSlice(initCallData, 0, 32)
                    op1.sender = Create2Factory.getDeployedAddress(ctr, salt)
                } else {
                    console.log("fillUserOp5")
                    if (provider == null) throw new Error('no entrypoint/provider')
                    op1.sender = await entryPoint!.callStatic.getSenderAddress(op1.initCode!).catch(e => e.errorArgs.sender)
                }
            }
            console.log("fillUserOp6")
            if (op1.verificationGasLimit == null) {
                console.log("fillUserOp7")
                if (provider == null) throw new Error('no entrypoint/provider')
                const initEstimate = await provider.estimateGas({
                    from: entryPoint?.address,
                    to: initAddr,
                    data: initCallData,
                    gasLimit: 10e6
                })
                op1.verificationGasLimit = BigNumber.from(DefaultsForUserOp.verificationGasLimit).add(initEstimate)
            }
        }
        console.log("fillUserOp8")
        if (op1.nonce == null) {
            console.log("fillUserOp9", op)
            if (provider == null) throw new Error('must have entryPoint to autofill nonce')
            const c = new Contract(op.sender!, [`function ${getNonceFunction}() view returns(uint256)`], provider)
            console.log("c address: ", c.address)
            try {
               op1.nonce =  await c[getNonceFunction]()
            } catch (error) {
                op1.nonce=0
            }
            // op1.nonce = await c[getNonceFunction]().catch((error: any) => {

            //     console.error("get nonce error", error)
            // }
            // )
        }
        console.log("fillUserOp10")
        if (op1.callGasLimit == null && op.callData != null) {
            if (provider == null) throw new Error('must have entryPoint for callGasLimit estimate')
            const gasEtimated = await provider.estimateGas({
                from: entryPoint?.address,
                to: op1.sender,
                data: op1.callData
            })

            // console.log('estim', op1.sender,'len=', op1.callData!.length, 'res=', gasEtimated)
            // estimateGas assumes direct call from entryPoint. add wrapper cost.
            op1.callGasLimit = gasEtimated // .add(55000)
        }
        if (op1.maxFeePerGas == null) {
            if (provider == null) throw new Error('must have entryPoint to autofill maxFeePerGas')
            const block = await provider.getBlock('latest')
            console.log("block", block)
            op1.maxFeePerGas = block.baseFeePerGas!.add(op1.maxPriorityFeePerGas ?? DefaultsForUserOp.maxPriorityFeePerGas)
        }
        // TODO: this is exactly what fillUserOp below should do - but it doesn't.
        // adding this manually
        if (op1.maxPriorityFeePerGas == null) {
            op1.maxPriorityFeePerGas = DefaultsForUserOp.maxPriorityFeePerGas
        }
        const op2 = this.fillUserOpDefaults(op1)
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        if (op2.preVerificationGas.toString() === '0') {
            // TODO: we don't add overhead, which is ~21000 for a single TX, but much lower in a batch.
            op2.preVerificationGas = this.callDataCost(packUserOp(op2, false))
        }
        console.log("op2", op2)
        return op2
    }


    async fillAndSign(op: Partial<UserOperation>, signer: Wallet | Signer, entryPoint?: EntryPoint, getNonceFunction = 'getNonce'): Promise<UserOperation> {
        const provider = entryPoint?.provider
        const op2 = await this.fillUserOp(op, entryPoint, getNonceFunction)

        const chainId = await provider!.getNetwork().then(net => net.chainId)
        const message = arrayify(getUserOpHash(op2, entryPoint!.address, chainId))

        return {
            ...op2,
            signature: await signer.signMessage(message)
        }
    }
    getWallet = (privateKey: string): Wallet => {
        return new ethers.Wallet(privateKey, chainHelper.jsonRpcProvider());
    };

    getAccountAPI = async (
        privateKey: string,
        accountAddress?: string
    ): Promise<SimpleAccountAPI> => {
        const accountOwner = this.getWallet(privateKey);

        return this.getAccountAPIFromWallet(accountOwner, accountAddress);
    };

    getAccountAPIFromWallet = async (
        wallet: Wallet,
        accountAddress?: string
    ): Promise<any> => {
        return { walletAddress: wallet.address, accountAddress }
        // take a new account address
    };

}
export const aaHelper = singleton(AAHelper);
