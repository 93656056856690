/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../../../common";
import type {
  GuardManager,
  GuardManagerInterface,
} from "../../../../../../@gnosis.pm/safe-contracts/contracts/base/GuardManager.sol/GuardManager";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "guard",
        type: "address",
      },
    ],
    name: "ChangedGuard",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "guard",
        type: "address",
      },
    ],
    name: "setGuard",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

const _bytecode =
  "0x608060405234801561001057600080fd5b506101a0806100206000396000f3fe608060405234801561001057600080fd5b506004361061002b5760003560e01c8063e19a9dd914610030575b600080fd5b61004361003e36600461012d565b610045565b005b61004d6100be565b7f4a204f620c8c5ccdca3fd54d003badd85ba500436a431f0cbda4f558c93c34c881815560405173ffffffffffffffffffffffffffffffffffffffff831681527f1151116914515bc0891ff9047a6cb32cf902546f83066499bcf8ba33d2353fa29060200160405180910390a15050565b33301461012b576040517f08c379a000000000000000000000000000000000000000000000000000000000815260206004820152600560248201527f4753303331000000000000000000000000000000000000000000000000000000604482015260640160405180910390fd5b565b60006020828403121561013f57600080fd5b813573ffffffffffffffffffffffffffffffffffffffff8116811461016357600080fd5b939250505056fea264697066735822122075a4c715379f5fb71204e940810e01a9960d9147b25ced9c0091443d1d036d7564736f6c634300080f0033";

type GuardManagerConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: GuardManagerConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class GuardManager__factory extends ContractFactory {
  constructor(...args: GuardManagerConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<GuardManager> {
    return super.deploy(overrides || {}) as Promise<GuardManager>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): GuardManager {
    return super.attach(address) as GuardManager;
  }
  override connect(signer: Signer): GuardManager__factory {
    return super.connect(signer) as GuardManager__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): GuardManagerInterface {
    return new utils.Interface(_abi) as GuardManagerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): GuardManager {
    return new Contract(address, _abi, signerOrProvider) as GuardManager;
  }
}
