const singletonMap: Record<any, any> = {};
export const singleton = <T extends Object>(type: (new (...args: any[]) => T), ...args: any[]): T => {
    const typeName = type.name;
    
    let instance = Object.values(singletonMap).find(s => s === typeName);
    if (!instance) {
        instance = new type(...args);
        singletonMap[typeName] = instance;
    }
	
	return instance;
}

export interface ISingleton {
    OnCreate: () => void;
}