/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../common";
import type {
  TestCounter,
  TestCounterInterface,
} from "../../../contracts/test/TestCounter";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "CalledFrom",
    type: "event",
  },
  {
    inputs: [],
    name: "count",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "countFail",
    outputs: [],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "counters",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "repeat",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    name: "gasWaster",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "justemit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "offset",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "xxx",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

const _bytecode =
  "0x608060405234801561001057600080fd5b506103a4806100206000396000f3fe608060405234801561001057600080fd5b506004361061007d5760003560e01c8063a5e9585f1161005b578063a5e9585f146100a7578063be65ab8c146100d9578063caece693146100f9578063d55565441461010157600080fd5b806306661abd14610082578063278ddd3c1461008c578063a1b4689014610094575b600080fd5b61008a61010a565b005b61008a610137565b61008a6100a236600461021d565b61016c565b6100c76100b5366004610299565b60016020526000908152604090205481565b60405190815260200160405180910390f35b6100c76100e73660046102b2565b60006020819052908152604090205481565b61008a6101b7565b6100c760025481565b3360009081526020819052604090205461012590600161031e565b33600090815260208190526040902055565b6040513381527ffb3b4d6258432a9a3d78dd9bffbcb6cfb1bd94f58da35fd530d08da7d1d058329060200160405180910390a1565b60015b8381116101b1576002805490600061018683610336565b90915550506002546000908152600160205260409020819055806101a981610336565b91505061016f565b50505050565b6040517f08c379a000000000000000000000000000000000000000000000000000000000815260206004820152600c60248201527f636f756e74206661696c65640000000000000000000000000000000000000000604482015260640160405180910390fd5b60008060006040848603121561023257600080fd5b83359250602084013567ffffffffffffffff8082111561025157600080fd5b818601915086601f83011261026557600080fd5b81358181111561027457600080fd5b87602082850101111561028657600080fd5b6020830194508093505050509250925092565b6000602082840312156102ab57600080fd5b5035919050565b6000602082840312156102c457600080fd5b813573ffffffffffffffffffffffffffffffffffffffff811681146102e857600080fd5b9392505050565b7f4e487b7100000000000000000000000000000000000000000000000000000000600052601160045260246000fd5b60008219821115610331576103316102ef565b500190565b60007fffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff8203610367576103676102ef565b506001019056fea26469706673582212201f234ce027d81b210629be827a3f484a76a4e0b18ac5b58dffa075a93930638464736f6c634300080f0033";

type TestCounterConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: TestCounterConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class TestCounter__factory extends ContractFactory {
  constructor(...args: TestCounterConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<TestCounter> {
    return super.deploy(overrides || {}) as Promise<TestCounter>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): TestCounter {
    return super.attach(address) as TestCounter;
  }
  override connect(signer: Signer): TestCounter__factory {
    return super.connect(signer) as TestCounter__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): TestCounterInterface {
    return new utils.Interface(_abi) as TestCounterInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): TestCounter {
    return new Contract(address, _abi, signerOrProvider) as TestCounter;
  }
}
