import { singleton } from "./singleton";

class HttpHelper {
	isOK = (status: any): boolean => {
		return status > 199 && status < 300;
	};

	isStatusCode = (status: any): boolean => {
		return status >= 0 && status <= 511;
	};

	buildURLQuery = (obj: any) => {
		return Object.entries(obj)
			.map(([key, val]) => `${key}=${val}`)
			.join("&");
	};
}
export const httpHelper = singleton(HttpHelper);
