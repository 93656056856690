import { singleton } from "./singleton";

class JSHelper {
	isJSON = (value: any): boolean => {
		const vs = value + "";
		return vs.startsWith("{") || vs.startsWith("[");
	};

	isObj = (value: any): boolean => {
		return (
			typeof value === "object" && !Array.isArray(value) && value !== null
		);
	};

	errorToJSON = (err: any): any => {
		return this.isJSON(err)
			? err
			: { error: err["message"] ? `${err.message}` : `${err}` };
	};

	handleError = (err: any): string => {
		const emsg = err.message ?? err.error ?? err + "";
		console.debug(emsg);
		return emsg;
	};

	hashCode = (s: string) =>
		s.split("").reduce((a, b) => {
			a = (a << 5) - a + b.charCodeAt(0);
			return a & a;
		}, 0);

	unescapeSlashes = (str: string) => {
		// add another escaped slash if the string ends with an odd
		// number of escaped slashes which will crash JSON.parse
		let parsedStr = str?.replace(/(^|[^\\])(\\\\)*\\$/, "$&\\");

		// escape unescaped double quotes to prevent error with
		// added double quotes in json string
		parsedStr = parsedStr?.replace(/(^|[^\\])((\\\\)*")/g, "$1\\$2");

		try {
			if (parsedStr) parsedStr = JSON.parse(`"${parsedStr}"`);
		} catch (e) {
			return str;
		}
		return parsedStr || "";
	};

	base64URLEncode = (str: Buffer) =>
		str
			.toString("base64")
			.replace(/\+/g, "-")
			.replace(/\//g, "_")
			.replace(/=/g, "");
	base64 = (str: string) => Buffer.from(str).toString("base64");
	base64Decode = (str: string) => Buffer.from(str, "base64").toString();

	utc2Date = (utc: number): Date => new Date(utc * 1000);
	utc2Str = (utc: number): string => this.utc2Date(utc).toLocaleString();

	toInt = (value?: string, defaultValue?: number): number => {
		if (value === undefined) return defaultValue || NaN;

		let val = parseInt(value);
		return this.checkNumber(val, defaultValue);
	};

	toNumber = (value?: string, defaultValue?: number): number => {
		if (value === undefined) return defaultValue || NaN;

		let val = parseFloat(value);
		return this.checkNumber(val, defaultValue);
	};

	checkNumber = (value: number, defaultValue?: number): number => {
		return value * 1 === value
			? value
			: defaultValue !== undefined
			? defaultValue
			: value;
	};

	parseJWT = (token: string): any => {
		if (!token) return;
		var base64Payload = token.split(".")[1];
		var payload = Buffer.from(base64Payload, "base64");
		return JSON.parse(payload.toString());
	};
}
export const jsHelper = singleton(JSHelper);
